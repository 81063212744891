import { format } from "@bsgp/lib-date";
import { defined, tryit, isObject, isArray } from "@bsgp/lib-core";
import { extractArgs, removeUndefinedKeys } from "./lib/functions";

const ft = {
  Input: (...args) => {
    const [key, options] = extractArgs(args);

    const valueHelp = options.onValueHelp && {
      showValueHelp: true,
      valueHelpRequest: options.onValueHelp
    };

    const valueHelpV2 = options.valueHelpV2 && {
      showValueHelp: true,
      valueHelpV2: options.valueHelpV2
    };

    const multiple = options.multiple;
    const isAmount = options.isAmount;
    const isQuantity = options.isQuantity;
    const unit = options.unit;

    return {
      type: "Input",
      name: key || options.name,
      multiple,
      isAmount,
      isQuantity,
      textOnSort: options.textOnSort,
      unit,
      properties: removeUndefinedKeys({
        ...(multiple
          ? { showClearIcon: true, startSuggestion: 0, showValueHelp: false }
          : {}),
        ...(multiple
          ? { tokenUpdate: options.onChange }
          : { change: options.onChange }),
        submit: options.onSubmit,
        ...valueHelp,
        ...valueHelpV2,
        ...options.properties
      }),
      items: {
        properties: {
          ...(tryit(() => options.items.properties) || {}),
          key: "{key}",
          text: "{text}",
          additionalText: "{additionalText}"
        },
        list: options.list || tryit(() => options.items.list) || []
      }
    };
  },
  TextArea: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "TextArea",
      name: key || options.name,
      properties: {
        change: options.onChange,
        ...options.properties
      }
    };
  },
  CodeEditor: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "CodeEditor",
      name: key || options.name,
      properties: {
        change: options.onChange,
        ...options.properties
      }
    };
  },
  Text: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type:
        options.colorful === true
          ? "MessageStrip"
          : options.expandable === true
          ? "ExpandableText"
          : options.formatted
          ? "FormattedText"
          : "Text",
      name: key || options.name,
      styleClasses: options.styleClasses,
      textOnSort: options.textOnSort,
      properties: {
        ...(options.expandable === true
          ? { overflowMode: window.sap.m.ExpandableTextOverflowMode.Popover }
          : {}),
        ...options.properties
      }
    };
  },
  Switch: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      name: key || options.name,
      type: "Switch",
      properties: {
        customTextOff: options.offText,
        customTextOn: options.onText,
        change: options.onChange,
        ...options.properties
      }
    };
  },
  Tile: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      name: key || options.name,
      type: "GenericTile",
      properties: {
        press: options.onPress,
        ...options.properties
      }
    };
  },
  Select: (...args) => {
    const [key, options] = extractArgs(args);
    const itemsList = options.list || tryit(() => options.items.list) || [];

    return {
      type: "Select",
      name: key || options.name,
      combine: options.combine,
      properties: {
        change: options.onChange,
        ...options.properties
      },
      items: {
        properties: {
          ...(tryit(() => options.items.properties) || {}),
          key: "{key}",
          text: options.combine === true ? "{key} - {text}" : "{text}",
          icon: "{icon}",
          enabled: "{enabled}"
        },
        list: (isArray(itemsList) ? itemsList : []).map(each => {
          if (isObject(each)) {
            return { ...each, enabled: defined(each.enabled, true) };
          }
          return {
            key: each,
            text: each,
            enabled: true
          };
        })
      }
    };
  },
  Number: (...args) => {
    const [key, options] = extractArgs(args);
    const newProperties = {
      unit: options.unit,
      ...options
    };
    if (options.properties) {
      delete newProperties.properties;
      Object.keys(options.properties).forEach(prop => {
        newProperties[prop] = options.properties[prop];
      });
    }

    return {
      type: "ObjectNumber",
      name: key || options.name,
      properties: newProperties
    };
  },
  AmountAsA1: (...args) => {
    const [key, options] = extractArgs(args);
    const newOptions = defined(options, {});
    newOptions.asA1 = true;
    return ft.Amount(key, newOptions);
  },
  Amount: (...args) => {
    const [key, options] = extractArgs(args);
    const newOptions = defined(options, {});
    newOptions.isAmount = true;
    delete newOptions.isQuantity;

    return ft.Number(key, newOptions);
  },
  Quantity: (...args) => {
    const [key, options] = extractArgs(args);
    const newOptions = defined(options, {});
    newOptions.isQuantity = true;
    delete newOptions.isAmount;

    return ft.Number(key, newOptions);
  },
  Attribute: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "ObjectAttribute",
      name: key || options.name,
      title: options.title
    };
  },
  Identifier: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "ObjectIdentifier",
      name: key || options.name,
      text: options.text,
      normalWeight: options.normalWeight
    };
  },
  Status: (...args) => {
    const [key, options] = extractArgs(args);
    const newProperties = {
      emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
      ...options
    };
    if (options.properties) {
      delete newProperties.properties;
      Object.keys(options.properties).forEach(prop => {
        newProperties[prop] = options.properties[prop];
      });
    }

    return {
      type: "ObjectStatus",
      name: key || options.name,
      properties: newProperties,
      textOnSort: options.textOnSort
    };
  },
  Spacer: (...args) => {
    const [key, options] = extractArgs(args);
    const newProperties = {
      ...options
    };
    if (options.properties) {
      delete newProperties.properties;
      Object.keys(options.properties).forEach(prop => {
        newProperties[prop] = options.properties[prop];
      });
    }

    return {
      type: "ToolbarSpacer",
      name: key || options.name,
      properties: newProperties
    };
  },
  HTML: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "HTML",
      name: key || options.name,
      properties: {
        ...options.properties
      }
    };
  },
  PDFViewer: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "PDFViewer",
      name: key || options.name,
      properties: {
        ...options.properties,
        showDownloadButton: false,
        displayType: window.sap.m.PDFViewerDisplayType.Embedded
      }
    };
  },
  DateTime: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "DateTime",
      name: key || options.name,
      format: options.format || ft.Constants.DateTime.Format.UI5,
      displayFormat: options.displayFormat,
      properties: {
        ...options.properties,
        change: options.onChange
      }
    };
  },
  Date: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "Date",
      name: key || options.name,
      format: options.format || ft.Constants.Date.Format.UI5,
      displayFormat: options.displayFormat || ft.Constants.Date.Format.UI5,
      properties: {
        ...options.properties,
        change: options.onChange
      }
    };
  },
  Time: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "Time",
      name: key || options.name,
      format: options.format || ft.Constants.Time.Format.UI5,
      displayFormat: options.displayFormat || ft.Constants.Time.Format.UI5,
      properties: {
        ...options.properties,
        change: options.onChange
      }
    };
  },
  DateRange: (...args) => {
    const [key, options] = extractArgs(args);

    return {
      type: "DateRange",
      name: key || options.name,
      format: options.format || ft.Constants.Date.Format.UI5,
      displayFormat: options.displayFormat || ft.Constants.Date.Format.UI5,
      properties: {
        change: options.onChange,
        ...options.properties
      }
    };
  },
  ComboBox: (...args) => {
    const [key, options] = extractArgs(args);
    const multiple = options.multiple;

    const config = {
      type: "ComboBox",
      name: key || options.name,
      properties: {
        ...options.properties,
        change: options.onChange,
        showSecondaryValues: true
      },
      multiple,
      combine: options.combine,
      items: {
        properties: {
          ...(tryit(() => options.items.properties) || {}),
          key: "{key}",
          text: options.combine === true ? "{key} - {text}" : "{text}",
          additionalText: "{additionalText}"
        },
        list: (options.list || tryit(() => options.items.list) || []).map(
          each => {
            if (isObject(each)) {
              return each;
            }
            return {
              key: each,
              text: each
            };
          }
        )
      }
    };
    if (multiple) {
      delete config.properties.change;
      config.properties.selectionFinish = options.onChange;
    }
    return config;
  },
  Button: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "Button",
      name: key || options.name,
      number: options.number,
      isScannerButton: options.isScannerButton,
      onScan: options.onScan,
      onError: options.onError,
      confirmMessage: options.confirmMessage,
      properties: {
        ...options.properties,
        press: options.onPress
      }
    };
  },
  ScannerButton: (...args) => {
    const result = ft.Button(...args);
    result.isScannerButton = true;
    return result;
  },
  Buttons: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "SegmentedButton",
      name: key || options.name,
      label: options.label,
      properties: {
        selectionChange: options.onChange || options.onSelect,
        ...options.properties
      },
      list: options.list
    };
  },
  Comments: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "NotificationListItem",
      name: key || options.name,
      label: options.label,
      properties: {
        ...options.properties
      },
      list: options.list
    };
  },
  Radio: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "Radio",
      name: key || options.name,
      properties: {
        select: options.onChange || options.onSelect,
        ...options.properties
      },
      list: options.list
    };
  },
  Link: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "Link",
      name: key || options.name,
      properties: {
        text: options.text,
        press: options.onPress,
        ...options.properties
      }
    };
  },
  List: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "List",
      name: key || options.name,
      properties: { ...options.properties },
      items: {
        properties: {
          ...(tryit(() => options.items.properties) || {}),
          press: options.items.onPress
        },
        list: options.list || tryit(() => options.items.list) || []
      }
    };
  },
  CheckBox: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "CheckBox",
      name: key || options.name,
      hiddenData: options.hiddenData,
      styleClasses: options.styleClasses,
      properties: {
        select: options.onChange || options.onSelect,
        text: options.text,
        ...options.properties
      }
    };
  },
  Uploader: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "FileUploader",
      name: key || options.name,
      properties: {
        change: options.onChange,
        ...options.properties
      }
    };
  },
  Image: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "Image",
      name: key || options.name,
      bindArray: options.bindArray,
      hiddenData: options.hiddenData,
      properties: {
        ...options.properties
      }
    };
  },
  Avatar: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "Avatar",
      name: key || options.name,
      properties: {
        ...options.properties
      }
    };
  },
  StepInput: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "StepInput",
      name: key || options.name,
      label: options.label,
      hiddenData: options.hiddenData,
      properties: {
        change: options.onChange,
        ...options.properties
      }
    };
  },
  Label: (...args) => {
    const [key, options] = extractArgs(args);
    return {
      type: "Label",
      name: key || options.name,
      properties: {
        labelFor: options.for,
        ...options.properties
      }
    };
  }
};

ft.Constants = {
  DateTime: {
    Format: {
      Moment: format.default.datetime,
      UI5: format.ui5.datetime
    }
  },
  Date: {
    Format: {
      Moment: format.default.date,
      UI5: format.ui5.date
    }
  },
  Time: {
    Format: {
      Moment: format.default.time,
      UI5: format.ui5.time
    }
  }
};

export { ft };
