import { makeid } from "@bsgp/lib-core";
import {
  defined,
  tryit,
  isArray,
  isObject,
  isPromise,
  isString,
  isNumber
} from "@bsgp/lib-core";
import {
  create as amountCreator,
  numberWithCommas,
  removeCommas
} from "@bsgp/lib-amount";
import { create as quantity } from "@bsgp/lib-quantity";
import {
  removeUndefinedKeys,
  getParentTable,
  getParentItem,
  refineProps,
  isBound
} from "./lib/functions";
import { getHeaderCompWithCustomKey } from "./lib/use-pagination";
import * as Sentry from "@sentry/react";

const fieldComponent = {};

function convertNumber(value, unit, options = {}) {
  const { isAmount, isQuantity, asA1 } = options;
  if (value === 0) {
    return value.toString();
  } else if (!value) {
    return value;
  }

  try {
    if (isAmount === true) {
      return amountCreator(value, unit, 1, { asA1 }).formattedNumber;
    } else if (isQuantity === true) {
      return quantity(value, unit).formattedNumber;
    } else {
      return numberWithCommas(value);
    }
  } catch (ex) {
    console.error("convertNumber error", ex.message);
    return value;
  }
}

function convertNumberToNew(value, { isAmount, isQuantity, unit, asA1 }) {
  const valueIsBound = isBound(value);
  let number = 0;
  if (valueIsBound) {
    const newValue = value.replace("{", "").replace("}", "");
    const newUnit = defined(unit, "")
      .replace("{", "")
      .replace("}", "");
    const parts = [{ path: newValue }];
    if (newUnit) {
      parts.push({ path: newUnit });
    }
    number = {
      parts,
      formatter: (sValue, sUnit) => {
        if (!sValue) {
          return sValue;
        }
        return convertNumber(sValue, isBound(unit) ? sUnit : unit, {
          isAmount: isAmount,
          isQuantity: isQuantity,
          asA1
        });
      }
    };
  } else {
    number = convertNumber(value, unit, {
      isAmount: isAmount,
      isQuantity: isQuantity,
      asA1
    });
  }
  return number;
}

fieldComponent.ObjectNumber = (component, text) => ({
  name,
  value,
  properties
}) => {
  const number = convertNumberToNew(value, {
    isAmount: properties.isAmount,
    isQuantity: properties.isQuantity,
    unit: properties.unit,
    asA1: properties.asA1
  });

  const oComp = component.set(window.sap.m.ObjectNumber, {
    name,
    number,
    ...properties
  });
  oComp.addStyleClass("ft_display_bottom_dash_on_table");
  return oComp;
};

fieldComponent.ObjectAttribute = component => ({
  name,
  value,
  properties,
  title,
  normalWeight
}) => {
  return component.set(window.sap.m.ObjectAttribute, {
    name,
    title,
    text: value,
    ...properties
  });
};

fieldComponent.ObjectIdentifier = component => ({
  name,
  value,
  properties,
  text,
  normalWeight
}) => {
  return component.set(window.sap.m.ObjectIdentifier, {
    name,
    title: value,
    text: text,
    emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
    ...properties,
    callback: oComp => {
      oComp.removeStyleClass("ft-normal-font-weight");
      if (normalWeight === true) {
        oComp.addStyleClass("ft-normal-font-weight");
      }
    }
  });
};

fieldComponent.ObjectStatus = component => ({ name, value, properties }) => {
  return component.set(window.sap.m.ObjectStatus, {
    name,
    text: value,
    ...properties
  });
};

fieldComponent.ToolbarSpacer = component => ({ name, properties }) => {
  return component.set(window.sap.m.ToolbarSpacer, {
    name,
    ...properties
  });
};

fieldComponent.HTML = component => ({ name, value, properties }) => {
  // the content must be enclosed in tags, pure text is not supported.
  const createBody = data =>
    new DOMParser()
      .parseFromString(data, "text/html")
      .getElementsByTagName("body")[0];
  let content;
  if (!/<\/?[a-z][\s\S]*>/i.test(value)) {
    content = `<div>${createBody(value).innerText}</div>`;
  } else content = `<div>${createBody(value).innerHTML}</div>`;

  return component.set(window.sap.ui.core.HTML, {
    name,
    content,
    ...properties
  });
};

fieldComponent.PDFViewer = component => ({ name, value, properties }) => {
  return component.set(window.sap.m.PDFViewer, {
    name,
    value,
    ...properties
  });
};

fieldComponent.DateTime = component => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  return component.set(window.sap.m.DateTimePicker, {
    name,
    value,
    ...properties,
    ...(format && {
      valueFormat: format,
      displayFormat: displayFormat || format
    })
  });
};

fieldComponent.Date = component => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  return component.set(window.sap.m.DatePicker, {
    name,
    value,
    ...properties,
    ...(format && {
      valueFormat: format,
      displayFormat: displayFormat || format
    })
  });
};

fieldComponent.Time = component => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  return component.set(window.sap.m.TimePicker, {
    name,
    value,
    ...properties,
    ...(format && {
      valueFormat: format,
      displayFormat: displayFormat || format
    })
  });
};

fieldComponent.DateRange = component => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  const [dateValue, secondDateValue] = isArray(value) ? value : [value];
  const values = {};
  if (dateValue && isString(dateValue)) {
    if (secondDateValue) {
      values.value = [dateValue, secondDateValue].join(" - ");
    } else {
      values.value = [dateValue, dateValue].join(" - ");
    }
  } else {
    values.dateValue = dateValue;
    values.secondDateValue = secondDateValue || dateValue;
  }

  return component.set(window.sap.m.DateRangeSelection, {
    name,
    ...values,
    showCurrentDateButton: true,
    ...properties,
    ...(format && {
      valueFormat: format,
      displayFormat: displayFormat || format
    })
  });
};

fieldComponent.Radio = (component, customData) => ({
  name,
  value,
  properties = {},
  list = []
}) => {
  const newList = list.map(each => {
    if (isString(each)) {
      return { key: each, text: each };
    }
    return each;
  });
  const valueIsBound = isBound(value);
  return component.set(window.sap.m.RadioButtonGroup, {
    name,
    ...properties,
    ...(properties.select && {
      select: oEvent => {
        const selectedButton = oEvent.getSource().getSelectedButton();
        const oCustomData = selectedButton.getCustomData().find(data => {
          return data.getKey() === "key";
        });
        if (oCustomData) {
          properties.select(oEvent, oCustomData.getValue());
        } else {
          properties.select(oEvent, undefined);
        }
      }
    }),
    selectedIndex: valueIsBound
      ? {
          parts: [{ path: value.replace("{", "").replace("}", "") }],
          formatter: sKey => {
            if (!sKey) {
              return 0;
            }
            return newList.findIndex(button => button.key === sKey);
          }
        }
      : newList.findIndex(button => button.key === value),
    columns: properties.columns || newList.length,
    buttons: newList.map(button => {
      const encodedButtonKey = button.key
        .toString()
        .split("")
        .map((char, idx) => {
          if (char.search(/[a-zA-Z0-9_-]/) >= 0) {
            return char;
          }
          return char.charCodeAt(idx);
        })
        .join("");
      const btnName = [name, encodedButtonKey].join("-");
      return component.set(window.sap.m.RadioButton, {
        name: btnName,
        customData: customData.set({
          name: [btnName, encodedButtonKey].join("_"),
          key: "key",
          value: button.key
        }),
        text: button.text
      });
    })
  });
};

fieldComponent.SegmentedButton = (component, vBox) => ({
  name,
  value,
  label,
  properties = {},
  list = []
}) => {
  const oButtons = component.set(window.sap.m.SegmentedButton, {
    name,
    ...properties,
    ...(properties.selectionChange && {
      selectionChange: oEvent => {
        const selectedItem = oEvent.getParameters().item;
        const selectedKey = selectedItem.getKey();
        properties.selectionChange(oEvent, { selectedKey });
      }
    }),
    selectedKey: value,
    items: list.map(button => {
      const btnName = [name, button.key].join("-");
      return component.set(window.sap.m.SegmentedButtonItem, {
        name: btnName,
        key: button.key,
        text: button.text,
        icon: button.icon,
        width: button.width
      });
    })
  });
  if (label) {
    return component.set(window.sap.ui.layout.VerticalLayout, {
      name: [name, "siWrapper"].join("_"),
      content: [
        component.set(window.sap.m.Label, {
          name: [name, "label"].join("_"),
          text: label,
          labelFor: oButtons.getIdForLabel(),
          ...properties
        }),
        oButtons
      ]
    });
  }
  return oButtons;
};

function getListItemConfig(options = {}) {
  const { sorter = [], component, name, properties, valueIsBound } = options;
  return {
    path:
      valueIsBound === true ? "/".concat([name, "items"].join("-")) : "/items",
    templateShareable: true,
    sorter: sorter.map(
      each =>
        new window.sap.ui.model.Sorter(each.key, false, function(oContext) {
          const data = oContext.getObject();
          return {
            key: data[each.key],
            text: data[each.text]
          };
        })
    ),
    template: component.set(window.sap.ui.core.ListItem, {
      name: [name, "items"].join("-"),
      settings: {
        ...properties
        // key: properties.key,
        // text: properties.text,
        // additionalText: properties.additionalText
      }
    })
  };
}

fieldComponent.ComboBox = component => ({
  name,
  value,
  properties = {},
  sorter = [],
  itemProperties,
  itemData,
  multiple
}) => {
  const valueIsBound = isBound(value);

  return component.set(
    multiple ? window.sap.m.MultiComboBox : window.sap.m.ComboBox,
    {
      name,
      ...(multiple
        ? { selectedKeys: valueIsBound ? value : "{/value}" }
        : {
            selectedKey: valueIsBound ? value : "{/value}"
          }),
      ...properties,
      items: getListItemConfig({
        valueIsBound,
        sorter,
        component,
        name,
        length: itemData.length,
        properties: itemProperties
      }),
      callback: (comp, isInitial, setData) => {
        if (valueIsBound) {
          // pass
        } else {
          setData(
            { items: itemData, value },
            { template: comp.getParent(), length: itemData.length }
          );
          if (multiple) {
            // pass
          } else {
            if (itemData.findIndex(each => each.key === value) < 0) {
              // value가 리스트에 없을 경우
              console.log(
                "setValue for combobox since the value is not existing in list",
                name,
                value
              );
              comp.setValue(value);
            }
          }
        }
      }
    }
  );
};

fieldComponent.Select = (select, component) => ({
  name,
  value,
  properties = {},
  // sorter = [],
  itemProperties,
  itemData
}) => {
  const valueIsBound = isBound(value);

  return select.set({
    name,
    selectedKey: valueIsBound ? value : "{/value}",
    ...properties,
    items: getListItemConfig({
      valueIsBound,
      component,
      name,
      properties: itemProperties
    }),
    // {
    //   path: valueIsBound ? "/".concat([name, "items"].join("-")) : "/items",
    //   // sorter: sorter.map(
    //   //   each =>
    //   //     new window.sap.ui.model.Sorter
    //   // (each.key, false, function(oContext) {
    //   //       const data = oContext.getObject();
    //   //       return {
    //   //         key: data[each.key],
    //   //         text: data[each.text]
    //   //       };
    //   //     })
    //   // ),
    //   templateShareable: true,
    //   template: component.set(window.sap.ui.core.ListItem, {
    //     name: [name, "items"].join("-"),
    //     settings: {
    //       key: "{key}",
    //       text: combine === true ? "{key} - {text}" : "{text}",
    //       enabled: "{enabled}"
    //     }
    //   })
    // },
    callback: (comp, isInitial, setData) => {
      const oParent = comp.getParent();
      // if (oParent && oParent.getMetadata().getName()
      //  === "sap.m.ColumnListItem") {
      if (valueIsBound) {
        // pass
      } else {
        setData(
          {
            items: itemData,
            value
          },
          { template: oParent }
        );
        // 초기값이 적용이 되지 않는 문제가 있어 추가함.
        comp.setSelectedKey(value);
      }
    }
  });
};

fieldComponent.GenericTile = (component, customData) => ({
  name,
  value,
  properties
}) => {
  const isNumberContent = isNumber(value);
  const useFeedNotNumeric = !!properties.contentText;

  const newValue = escapeValue(value);
  return component.set(
    window.sap.m.GenericTile,
    removeUndefinedKeys({
      name,
      // text: newValue,
      ...properties,
      icon: undefined,
      ...(isNumberContent
        ? {
            tileContent: component.set(window.sap.m.TileContent, {
              name: `${name}_tc`,
              ...properties,
              content: useFeedNotNumeric
                ? component.set(window.sap.m.FeedContent, {
                    name: `${name}_tc_fc`,
                    value: properties.scale
                      ? [newValue, properties.scale].join(" ")
                      : newValue,
                    ...properties
                  })
                : component.set(window.sap.m.NumericContent, {
                    name: `${name}_tc_nc`,
                    value: newValue,
                    ...properties
                  })
            })
          }
        : {}),
      callback: oComp => {
        // detachEvent(oComp, "click");
        // attachEvent(customData, name, oComp, "click", selectOnClickText);
      }
    })
  );
};

function selectOnClickText(event) {
  // ref to:
  // https://stackoverflow.com/a/14816523
  const that = event.target;
  let range, selection;
  if (window.getSelection && document.createRange) {
    selection = window.getSelection();
    if (selection.toString().length === 0) {
      range = document.createRange();
      range.selectNodeContents(that);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  } else if (document.selection && document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(that);
    range.select();
  }
}

fieldComponent.ExpandableText = (component, customData) => ({
  name,
  value,
  properties
}) => {
  const newValue = escapeValue(value);
  return component.set(window.sap.m.ExpandableText, {
    name,
    text: newValue,
    renderWhitespace: true,
    emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
    width: "auto",
    ...properties,
    callback: oComp => {
      detachEvent(oComp, "click");
      attachEvent(customData, name, oComp, "click", selectOnClickText);
    }
  });
};

fieldComponent.MessageStrip = (component, customData) => ({
  name,
  value,
  properties
}) => {
  const newValue = escapeValue(value);
  return component.set(window.sap.m.MessageStrip, {
    name,
    text: newValue,
    showIcon: true,
    ...properties,
    callback: oComp => {
      detachEvent(oComp, "click");
      attachEvent(customData, name, oComp, "click", selectOnClickText);
    }
  });
};

fieldComponent.FormattedText = component => ({ name, value, properties }) => {
  const newValue = escapeValue(value);
  return component.set(window.sap.m.FormattedText, {
    name,
    htmlText: newValue,
    // renderWhitespace: true,
    // emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
    width: "auto",
    ...properties
  });
};

fieldComponent.Switch = component => ({ name, value, properties }) => {
  return component.set(window.sap.m.Switch, {
    name,
    ...properties,
    state: value
  });
};

fieldComponent.Text = (text, customData) => ({
  name,
  value,
  properties,
  styleClasses
}) => {
  const newValue = escapeValue(value);

  return text.set({
    name,
    text: newValue,
    emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
    width: "auto",
    renderWhitespace: true,
    ...properties,
    callback: oComp => {
      oComp.removeStyleClass(styleClasses.join(" "));
      oComp.addStyleClass(styleClasses.join(" "));
      detachEvent(oComp, "click");
      attachEvent(customData, name, oComp, "click", selectOnClickText);
    }
  });
};

function getTableFromList(oContents) {
  for (let idx = 0; idx < oContents.length; idx += 1) {
    const oComp = oContents[idx];
    const className = oComp.getMetadata().getName();
    if (["sap.ui.table.TreeTable", "sap.m.Table"].includes(className)) {
      return oComp;
    }
    if (className === "sap.m.VBox") {
      const oItems = oComp.getItems();
      return getTableFromList(oItems);
    }
    if (className === "sap.m.ScrollContainer") {
      const oContents = oComp.getContent();
      return getTableFromList(oContents);
    }
  }
  return undefined;
}

function refineEventForDialog(oContent, callback = {}) {
  const onPressList = tryit(() => oContent.mEventRegistry.press) || [];
  if (onPressList.length > 0) {
    const prevHandler = onPressList[0].fFunction;
    const onPress = prevHandler.originalHandler || prevHandler;
    oContent.detachPress(prevHandler);

    const newHandler = async oEvent => {
      const oSource = oEvent.getSource();
      // oEvent.getSource = () => oSource;
      const oHeader = oSource.getParent();
      const oDialog = oHeader.getParent();
      const oContents = oDialog.getContent();
      const oTable = getTableFromList(oContents);
      let tableData;
      if (oTable) {
        if (oTable.getMetadata().getName() === "sap.m.Table") {
          const oItems = oTable.getSelectedItems();
          const items = oItems.map(item =>
            item.getBindingContext().getObject()
          );
          tableData = {
            oTable,
            oItems,
            items
          };
        } else {
          const indices = oTable.getSelectedIndices();
          const items = indices.map(index =>
            oTable.getContextByIndex(index).getObject()
          );
          tableData = {
            oTable,
            // oItems,
            items
          };
        }
      }

      const newEvent = {
        getSource: () => oSource
      };

      const oCustomDataVhIndex = oDialog.getCustomData().find(data => {
        return data.getKey() === "vhIndex";
      });
      const vhIndex = oCustomDataVhIndex && oCustomDataVhIndex.getValue();

      const buttonType = oContent.getType();
      const cbData = {
        oEvent: newEvent,
        oDialog,
        oHeader,
        index: vhIndex,
        ...tableData
      };

      let toCloseDialog = true;
      if (buttonType === window.sap.m.ButtonType.Reject) {
        if (callback.onBeforeCancel) {
          toCloseDialog = defined(callback.onBeforeCancel(cbData), true);
        }
      } else {
        if (callback.onConfirm) {
          let result = defined(callback.onConfirm(cbData), true);
          if (isPromise(result)) {
            result = await result;
          }
          toCloseDialog = result;
        }
      }

      newEvent.toCloseDialog = toCloseDialog;
      onPress(newEvent);

      if (buttonType === window.sap.m.ButtonType.Reject) {
        if (callback.onAfterCancel) {
          callback.onAfterCancel(cbData);
        }
      }
    };
    newHandler.originalHandler = onPress;
    oContent.attachPress(undefined, newHandler);
  }
}

fieldComponent.TextArea = component => ({ name, value, properties }) => {
  return component.set(window.sap.m.TextArea, {
    name,
    value: (value ?? "").toString(),
    ...properties
  });
};

fieldComponent.CodeEditor = (component, button, vBox, customData, fn) => ({
  name,
  value,
  properties,
  getFieldWithFactory
}) => {
  return vBox.set({
    name: [name, "ceWrapper"].join("_"),
    items: buildCodeEditor({ [name]: { properties, value } }, fn, {
      customData,
      component,
      button,
      doNotRefine: true,
      pageKey: name,
      getFieldWithFactory
    }).filter(Boolean)
  });
};

function escapeValue(value) {
  const newValue = isArray(value) ? value : defined(value, "").toString();

  // const bindingParser = window.sap.ui.base.ManagedObject.bindingParser;
  // const parseResult = tryit(() => bindingParser(newValue, null, true));
  // if (parseResult === undefined) {
  //   if (!isArray(newValue)) {
  //     newValue = bindingParser.escape(newValue);
  //   }
  // } else if (isObject(parseResult)) {
  //   if (isString(newValue)) {
  //     const jsonObj = tryit(() => JSON.parse(newValue));
  //     if (jsonObj !== undefined) {
  //       newValue = bindingParser.escape(newValue);
  //     }
  //   }
  // }

  return newValue;
}

fieldComponent.Input = (input, component, dialog, customData) => ({
  name,
  value,
  properties,
  refineProps,
  fn,
  multiple,
  isAmount,
  isQuantity,
  unit,
  items
}) => {
  let newValue = escapeValue(value);

  const disabledStyleClass = "disabled";
  const newProperties = { ...properties };

  if (properties.layoutData) {
    const oLayoutData = component.set(window.sap.m.FlexItemData, {
      name: [name, "layout_fc_Input"].join("_"),
      ...properties.layoutData
    });
    newProperties.layoutData = oLayoutData;
  }

  if (properties.mask) {
    return component.set(window.sap.m.MaskInput, {
      name,
      value: newValue,
      ...properties,
      callback: comp => {
        if (properties.enabled === false) {
          comp.addStyleClass(disabledStyleClass);
        } else {
          comp.removeStyleClass(disabledStyleClass);
        }
      }
    });
  } else {
    if (properties.valueHelpV2) {
      const {
        dialogId,
        onRequest,
        onConfirm,
        onBeforeCancel,
        onAfterCancel
      } = properties.valueHelpV2;
      const callback = refineProps(
        {
          onRequest,
          onBeforeCancel,
          onAfterCancel,
          onConfirm
        },
        fn
      );

      properties.valueHelpRequest = async oEvent => {
        const oInput = oEvent.getSource();
        const value = oInput.getValue();

        const oItem = getParentItem(oInput);
        const oParentTable = oItem && getParentTable(oItem);
        const vhIndex =
          oParentTable &&
          oParentTable.getItems().findIndex(item => item === oItem);

        const oDialog = dialog.get(`${dialogId}_dlgV2`);

        oDialog
          .getCustomHeader()
          .getContent()
          .forEach(oContent => {
            refineEventForDialog(oContent, callback);
          });

        oDialog.addCustomData(
          customData.set({
            name: [dialogId, "vhIndex"].join("_"),
            key: "vhIndex",
            value: vhIndex
          })
        );

        const oContents = oDialog.getContent();
        const oTable = getTableFromList(oContents);
        if (oTable && oTable.getMetadata().getName() === "sap.m.Table") {
          const oText = getHeaderCompWithCustomKey(oTable, "index");
          if (oText) {
            // oText.setBusy(true);
            // changePageIndexInfo(oTable);
            // changePageNumbers(oTable);
            if (oDialog.isOpen()) {
            } else {
              oDialog.open();
            }
          }
          const oModel = oTable.getModel();
          const data = oModel.getData();

          let newItems;
          if (callback.onRequest) {
            const result = callback.onRequest({
              value,
              oInput,
              row: tryit(() => oItem.getBindingContext().getObject()),
              index: vhIndex
            });
            if (isPromise(result)) {
              newItems = await result;
            } else {
              newItems = result;
            }
          }

          if (isArray(newItems)) {
            const transaction = Sentry.startInactiveSpan({
              name: "Use_onRequest_Returned",
              op: "onRequest",
              data: {
                result: newItems
              }
            });
            transaction.finish();
            // Finishing the transaction will send it to Sentry

            data.items = newItems;

            console.warn(
              [
                "It will be deprecated",
                "that fetching new items directly from onRequest"
              ].join(" ")
            );

            oModel.setData(data);
          }

          if (oText) {
            // oText.setBusy(false);
          } else {
          }
        } else {
          // dialog에 form만 있는 경우 onRequest가 실행이 안되는 이슈
          if (callback.onRequest) {
            callback.onRequest({ value, oInput });
          }
        }

        if (oDialog.isOpen()) {
        } else {
          oDialog.open();
        }
      };
      newProperties.valueHelpRequest = properties.valueHelpRequest;
    }

    if (multiple) {
      const itemProperties = tryit(() => items.properties, {});
      const itemData = tryit(() => items.list, []);

      return component.set(window.sap.m.MultiInput, {
        name,
        value: "",
        tokens: {
          path: "/value",
          template: component.set(window.sap.m.Token, {
            ...itemProperties,
            editable: "{editable}"
          })
        },
        ...properties,
        suggestionItems: getListItemConfig({
          component,
          name,
          properties: itemProperties
        }),
        callback: (comp, isInitial, setData) => {
          const newKeys = newValue.map(each => {
            if (isObject(each)) {
              return each.key;
            }
            return each;
          });
          const itemList = itemData.map(each => {
            if (isObject(each)) {
              return { ...each };
            }
            return { key: each, text: each };
          });
          const tokens = itemList.filter(each => newKeys.includes(each.key));
          const excludedKeys = newKeys.filter(
            key => tokens.findIndex(token => token.key === key) < 0
          );
          tokens.push(...excludedKeys.map(key => ({ key, text: key })));
          const objectValues = newValue.filter(each => isObject(each));
          tokens.forEach(token => {
            const objValue = objectValues.find(each => each.key === token.key);
            if (objValue) {
              Object.keys(objValue).forEach(objKey => {
                token[objKey] = objValue[objKey];
              });
            }
          });
          setData(
            {
              items: itemList.filter(each => !newKeys.includes(each.key)),
              value: tokens
            },
            { template: comp.getParent() }
          );
        }
      });
    }

    if (isAmount === true || isQuantity === true) {
      try {
        newValue = convertNumberToNew(newValue, {
          isAmount,
          isQuantity,
          unit
        });
        if (newProperties.valueState === undefined) {
          if (input.get(name) === undefined) {
            // pass
          } else {
            if (
              input.get(name).getValueState() !==
              window.sap.ui.core.ValueState.None
            ) {
              input.get(name).setValueState(window.sap.ui.core.ValueState.None);
              input.get(name).setValueStateText("");
            }
          }
        }
      } catch (ex) {
        console.error("convertNumberToNew error", ex.message);
        newProperties.valueState = window.sap.ui.core.ValueState.Error;
        newProperties.valueStateText = ex.message;
      }

      if (newProperties.textAlign === undefined) {
        newProperties.textAlign = window.sap.ui.core.TextAlign.Right;
      }

      if (properties.change) {
        newProperties.change = oEvent => {
          oEvent.mParameters.value = removeCommas(oEvent.mParameters.value);
          properties.change(oEvent);
        };
      }
    }

    return input.set({
      name,
      value: newValue,
      ...newProperties,
      callback: oComp => {
        const oInput = oComp.input.comp;
        oInput.setValue(newValue);

        if (properties.focus) {
          setTimeout(() => oComp.input.comp.focus(), 400);
        } else {
          setTimeout(() => {
            const oTable = properties.parentTableId
              ? component.get(properties.parentTableId)
              : getParentTable(oInput);

            if (oTable) {
              const tableClassName = oTable.getMetadata().getName();
              detachEvent(oInput, "focusin");
              attachEvent(
                customData,
                name,
                oInput,
                "focusin",
                focusinTableHandler({
                  tableName: oTable.getId(),
                  isTree: tableClassName === "sap.ui.table.TreeTable"
                })
              );
            } else {
              detachEvent(oInput, "focusin");
              attachEvent(
                customData,
                name,
                oInput,
                "focusin",
                focusinFormHandler
              );
            }
          });
        }

        if (properties.enabled === false) {
          oInput.addStyleClass(disabledStyleClass);
        } else {
          oInput.removeStyleClass(disabledStyleClass);
        }
      }
    });
  }
};

const focusinFormHandler = event => {
  // const pathList = event.originalEvent.path;
  // console.log("event.originalEvent:", event);
  window.currentFocusInputName = undefined;
  let el = event.target;

  // pathList.every(each => {
  while (el) {
    const tag = el.tagName;

    if (tag === "DIV") {
      if (el.classList.contains("sapMInput")) {
        window.currentFocusInputName = el.id;
        // console.log("inputId:", window.currentFocusInputName);
        // return false;
        break;
      }
    }
    // return true;
    el = el.parentElement;
  }

  setTimeout(function() {
    window.currentFocusInputName = undefined;
  }, 200);
};

const focusinTableHandler = ({ isTree = false, tableName }) => event => {
  // const pathList = event.originalEvent.path;
  window.currentFocusRowIndex = undefined;
  window.currentFocusColIndex = undefined;
  window.currentFocusTableName = tableName;

  let el = event.target;
  let stepToBreak = 2;
  // pathList.forEach(each => {
  while (el && stepToBreak > 0) {
    const tag = el.tagName;
    if (tag === "TD") {
      let colIndex = 0;
      let el2 = el;
      while (el2 != null) {
        el2 = el2.previousSibling;
        if (el2 === null) {
          break;
        }
        if (
          el2.classList.contains(isTree ? "sapUiTableCell" : "sapMListTblCell")
        ) {
          colIndex += 1;
        }
      }
      window.currentFocusColIndex = colIndex;
      console.log("colIndex:", colIndex);
      stepToBreak -= 1;
    }
    if (tag === "TR") {
      let rowIndex = 0;
      let el2 = el;
      while (el2 != null) {
        el2 = el2.previousSibling;
        if (el2 === null) {
          break;
        }
        if (
          el2.classList.contains(isTree ? "sapUiTableRow" : "sapMListTblRow")
        ) {
          rowIndex += 1;
        }
      }
      window.currentFocusRowIndex = rowIndex;
      console.log("rowIndex:", rowIndex);
      stepToBreak -= 1;
    }
    el = el.parentElement;
  }

  setTimeout(function() {
    window.currentFocusRowIndex = undefined;
    window.currentFocusColIndex = undefined;
  }, 200);
};

function attachEvent(customData, name, oComp, eventId, fHandler) {
  oComp.addCustomData(
    customData.set({
      name: [name, `${eventId}Handler`].join("_"),
      key: `${eventId}Handler`,
      value: fHandler
    })
  );

  oComp.attachBrowserEvent(eventId, fHandler);
}

function detachEvent(oComp, eventId) {
  const oCustomData = oComp.getCustomData().find(data => {
    return data.getKey() === `${eventId}Handler`;
  });
  if (oCustomData) {
    const focusinHandler = oCustomData.getValue();
    oComp.detachBrowserEvent(eventId, focusinHandler);
  }
}

fieldComponent.Link = link => ({ name, value, properties }) => {
  return link.set({
    name,
    text: properties.text || value,
    ...properties
  });
};

fieldComponent.List = (component, fn) => ({
  name,
  properties,
  itemProperties,
  itemData
}) => {
  return component.set(window.sap.m.List, {
    name,
    ...properties,
    items: {
      path: "/",
      template: component.set(window.sap.m.ActionListItem, {
        text: "{text}",
        tooltip: "{tooltip}",
        ...refineProps(itemProperties, fn)
      })
    },
    callback: (comp, isInitial, setData) => {
      setData(itemData);
    }
  });
};

function buildMenuItems(items, { name, getFieldWithFactory }) {
  if (!items) {
    return undefined;
  }
  return Object.keys(items).map(key => {
    // const itemProperties = refineProps(items[key].properties, fn);
    // console.log("menu:", items[key].value);

    return getFieldWithFactory(`${name}_${key}`, {
      value: items[key].value,
      component: {
        type: "MenuItem",
        confirmMessage: items[key].confirmMessage,
        properties: items[key].properties
      },
      items: items[key].items
    });
    //   name: ,
    //   text: items[key].value,
    //   ...itemProperties
    // });
  });
}

fieldComponent.Menu = (component, fn) => ({
  name,
  items,
  getFieldWithFactory
}) => {
  return component.set(window.sap.m.Menu, {
    name,
    items: buildMenuItems(items, { name, getFieldWithFactory })
  });
};

fieldComponent.MenuItem = (component, fn) => ({
  name,
  value,
  properties,
  confirmMessage,
  items,
  getFieldWithFactory
}) => {
  const needToConfirm = !!confirmMessage;
  let onPress;

  if (properties.press) {
    onPress = oEvent => {
      if (needToConfirm) {
        window.sap.ui.require(["sap/m/MessageBox"], function(MessageBox) {
          MessageBox.confirm(confirmMessage, {
            initialFocus: window.sap.m.MessageBox.Action.CANCEL,
            onClose: function(oAction) {
              if (oAction === window.sap.m.MessageBox.Action.OK) {
                properties.press(oEvent);
              }
            }
          });
        });
      } else {
        properties.press(oEvent);
      }
    };
  }
  return component.set(
    window.sap.m.MenuItem,
    removeUndefinedKeys({
      name: `${name}_${makeid(15)}`,
      text: value,
      ...properties,
      press: onPress,
      items: buildMenuItems(items, { name, getFieldWithFactory })
    })
  );
};

fieldComponent.MenuButton = (component, fn) => ({
  name,
  value,
  items,
  properties,
  getFieldWithFactory
}) => {
  return component.set(window.sap.m.MenuButton, {
    name,
    text: value,
    type: window.sap.m.ButtonType.Ghost,
    ...properties,
    menu: getFieldWithFactory(`${name}_menu`, {
      component: { type: "Menu" },
      items
    })
  });
};

fieldComponent.SearchField = (component, fn) => ({
  name,
  value,
  properties
}) => {
  return component.set(window.sap.m.SearchField, {
    name,
    text: value,
    ...properties
  });
};

fieldComponent.Button = (button, component) => ({
  name,
  value,
  number,
  confirmMessage,
  key,
  isScannerButton,
  onScan,
  onError,
  properties = {}
}) => {
  const { pageKey } = component;

  let customData;
  if (number !== undefined) {
    const strNum = number.toString();
    customData = component.set(window.sap.m.BadgeCustomData, {
      name: `${name}_badge`,
      key: `${name}_badge`,
      value: strNum
    });
  }

  const needToConfirm = !!confirmMessage;
  const scannerId = `${pageKey}_${key}_barcode_dialog`;

  let oScannerDialog;
  if (isScannerButton) {
    oScannerDialog = component.set(
      window.sap.ui.webc.fiori.BarcodeScannerDialog,
      {
        name: scannerId,
        scanError: oEvent => {
          const errorMsg = oEvent.getParameter("message");
          onError(errorMsg);
          component.get(scannerId).close();
        },
        scanSuccess: oEvent => {
          const barcode = oEvent.getParameter("text");
          onScan(barcode);
          component.get(scannerId).close();
        }
      }
    );
  }
  let onPress;
  if (isScannerButton) {
    onPress = () => {
      return component.get(scannerId).show();
    };
  } else if (properties.press) {
    onPress = oEvent => {
      if (needToConfirm) {
        window.sap.ui.require(["sap/m/MessageBox"], function(MessageBox) {
          MessageBox.confirm(confirmMessage, {
            initialFocus: window.sap.m.MessageBox.Action.CANCEL,
            onClose: function(oAction) {
              if (oAction === window.sap.m.MessageBox.Action.OK) {
                properties.press(oEvent);
              }
            }
          });
        });
      } else {
        properties.press(oEvent);
      }
    };
  }

  const oButton = button.set(
    removeUndefinedKeys({
      name,
      text: value,
      customData,
      ...properties,
      press: onPress
    })
  );

  return oScannerDialog ? [oButton, oScannerDialog] : oButton;
};

fieldComponent.Separator = component => ({ name, value, properties }) => {
  return component.set(window.sap.m.ToolbarSeparator, {
    name,
    ...properties
  });
};

fieldComponent.CheckBox = (checkbox, customData) => ({
  name,
  value,
  hiddenData,
  styleClasses,
  properties
}) => {
  return checkbox.set({
    name,
    selected: value,
    customData: customData.set({
      name: `${name}_hidden_data`,
      key: "hiddenData",
      value: hiddenData
    }),
    ...properties,
    callback: oComp => {
      oComp.removeStyleClass(styleClasses.join(" "));
      oComp.addStyleClass(styleClasses.join(" "));
    }
  });
};

fieldComponent.FileUploader = component => ({ name, value, properties }) => {
  return component.set(window.sap.ui.unified.FileUploader, {
    name,
    ...properties
  });
};

fieldComponent.Image = (component, hBox, customData) => ({
  name,
  value,
  bindArray,
  hiddenData,
  properties
}) => {
  // console.log("hiddenData:", hiddenData);
  if (!bindArray) {
    return component.set(window.sap.m.Image, {
      name,
      src: value,
      customData: customData.set({
        name: `${name}_hidden_data`,
        key: "hiddenData",
        value: hiddenData
      }),
      ...properties,
      callback: oComp => {
        oComp.addStyleClass("ft-image-max-width-100");
      }
    });
  }

  // console.log("bindArray:", bindArray, value);
  return hBox.set({
    name,
    ...properties,
    items: {
      path: [value.replace("{", "").replace("}", ""), "/"].join(""),
      templateShareable: true,
      template: component.set(window.sap.m.Image, {
        name: [name, "items"].join("-"),
        src: "{url}",
        width: "2rem",
        height: "2rem",
        customData: customData.set({
          name: `${name}_hidden_data`,
          key: "hiddenData",
          value: hiddenData
        }),
        ...properties
      })
    }
  });
};

fieldComponent.Avatar = component => ({ name, value, properties }) => {
  return component.set(window.sap.m.Avatar, {
    name,
    src: value,
    ...properties
  });
};

fieldComponent.NotificationListItem = (component, vBox) => ({
  name,
  value,
  label,
  properties
}) => {
  const oNotiCont = vBox.set({
    name: `${name}_cont`,
    layoutData: component.set(
      window.sap.m.FlexItemData,
      removeUndefinedKeys({
        name: "wrapLayout-" + name,
        // baseSize: "100%",
        growFactor: 1
        // maxWidth: col.maxWidth,
        // minWidth: col.minWidth
      })
    ),
    items: {
      path: [value.replace("{", "").replace("}", ""), "/"].join(""),
      templateShareable: true,
      template: component.set(window.sap.m.NotificationListItem, {
        name: `${name}_item`,
        showCloseButton: false,
        description: "{text}",
        counter: 5,
        datetime: "{when}",
        authorName: "{who}",
        authorInitials: "{who}",
        authorAvatarColor: "Accent8",
        ...properties,
        callback: oComp => {
          oComp.addStyleClass("noBottomLine");
          oComp.addStyleClass("noPointer");
        }
      })
    }
  });

  if (label) {
    return vBox.set({
      name: [name, "siWrapper"].join("_"),
      items: [
        component.set(window.sap.m.Label, {
          name: [name, "label"].join("_"),
          text: label,
          labelFor: oNotiCont.getIdForLabel(),
          ...properties
        }),
        oNotiCont
      ]
    });
  }
  return oNotiCont;
};

fieldComponent.StepInput = (component, vBox, customData) => ({
  name,
  value,
  label,
  hiddenData,
  properties
}) => {
  const oStepInput = component.set(window.sap.m.StepInput, {
    name,
    value,
    ...properties,
    customData: customData.set({
      name: `${name}_hidden_data`,
      key: "hiddenData",
      value: hiddenData
    })
  });
  if (label) {
    return vBox.set({
      name: [name, "siWrapper"].join("_"),
      items: [
        component.set(window.sap.m.Label, {
          name: [name, "label"].join("_"),
          text: label,
          labelFor: oStepInput.getIdForLabel(),
          ...properties
        }),
        oStepInput
      ]
    });
  }
  return oStepInput;
};

fieldComponent.Label = component => ({ name, value, properties }) => {
  return component.set(window.sap.m.Label, {
    name,
    text: value,
    ...properties
  });
};

function buildCodeEditor(codeeditor, fn, options = {}) {
  const {
    customData,
    component,
    button,
    doNotRefine = false,
    pageKey = "",
    getFieldWithFactory
  } = options;

  const ceKeys = Object.keys(codeeditor);
  const hasMultiCe = ceKeys.length > 1;

  const ceInstances = ceKeys.map(ceKey => {
    const ceObject = codeeditor[ceKey];
    const editorProperties = doNotRefine
      ? ceObject.properties
      : refineProps(ceObject.properties, fn);
    const ceId = [pageKey, ceKey].join("_");
    const buttons = isArray(ceObject.buttons)
      ? ceObject.buttons
      : isObject(ceObject.buttons)
      ? [ceObject.buttons]
      : [];
    const oToolbar = component.set(window.sap.m.Toolbar, {
      name: [ceId, "cedToolbar"].join("_"),
      content: [
        button.set({
          name: [ceId, "toggleFullscreen"].join("_"),
          icon: "sap-icon://full-screen",
          press: oEvent => {
            const oButton = oEvent.getSource();
            const jqObj = window.$(oButton.getDomRef());

            // refer to https://stackoverflow.com/a/32100295/13683961
            if (
              document.fullscreenElement ||
              document.webkitFullscreenElement ||
              document.mozFullScreenElement ||
              document.msFullscreenElement
            ) {
              oButton.setIcon("sap-icon://full-screen");
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
              }
            } else {
              oButton.setIcon("sap-icon://exit-full-screen");
              const element =
                jqObj.parents(".sapMDialog").get(0) ||
                jqObj.parents('[id$="_vbox_ceWrapper"]').get(0);
              if (element.requestFullscreen) {
                element.requestFullscreen();
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
              } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
              }
            }
          }
        })
      ].concat(
        buttons.map(btn => {
          const isMultiple = isArray(btn) && btn.length > 1;
          if (isMultiple) {
            const ceToolbarContentKey = [
              ceId,
              btn[0].name || btn[0].component.name
            ].join("_");
            return getFieldWithFactory(ceToolbarContentKey, {
              value: btn[0].text || btn[0].value,
              component: {
                type: "MenuButton",
                properties: {
                  icon: btn[0].icon,
                  ...btn[0].properties,
                  ...btn[0].component.properties,
                  useDefaultActionOnly: true,
                  buttonMode: window.sap.m.MenuButtonMode.Split,
                  defaultAction: btn[0].component.properties.press
                }
              },
              items: btn
                .filter((each, index) => index > 0)
                .reduce((acc, each, index) => {
                  acc[`${ceToolbarContentKey}_menuitem_${index}`] = {
                    value: each.text || each.value,
                    properties: {
                      icon: each.icon,
                      press: each.onPress,
                      ...each.properties,
                      ...each.component.properties
                    }
                  };
                  return acc;
                }, {})
            });
          }
          const ceToolbarContentKey = [
            ceId,
            btn.name || btn.component.name
          ].join("_");
          return getFieldWithFactory(ceToolbarContentKey, btn);
        })
      ),
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("sapUiSizeCompact");
        }
      }
    });

    const oEditor = component.set(window.sap.ui.codeeditor.CodeEditor, {
      name: ceId,
      height: "100%",
      ...editorProperties,
      ...(editorProperties.change && {
        change: oEvent => {
          oEvent.componentCtg = "codeeditors";
          return editorProperties.change(oEvent);
        }
      }),
      // fieldGroupIds: ["toggleWidth", "!Phone:80vw"],
      // value: ceObject.value,
      callback: (comp, isInitial) => {
        comp.setValue(ceObject.value);
        comp.setLayoutData(
          component.set(window.sap.m.FlexItemData, {
            name: `${ceId}_layout`,
            growFactor: 1
          })
        );
        comp._oEditor.setOption("tabSize", 2);
        comp._oEditor.setOption("useSoftTabs", true);
        comp._oEditor.setKeyboardHandler("ace/keyboard/vscode");
      }
    });
    // console.log("oEditor:", oEditor);

    return [oToolbar, oEditor];
  });

  if (hasMultiCe) {
    const ceWrapper = component.set(window.sap.m.IconTabBar, {
      name: [pageKey, "ceWrapper"].join("_"),
      select: oEvent => {
        // const oBar = oEvent.getSource();
        const oItem = oEvent.getParameters().item;
        const customDataList = oItem.getCustomData();
        const oCustomData = customDataList.find(
          each => each.getKey() === "select_handler"
        );
        if (oCustomData) {
          const { selectHandler, tabKey } = oCustomData.getValue();
          if (selectHandler) {
            selectHandler({ tabKey });
          }
        }
      },
      height: "100%",
      expandable: false,
      tabDensityMode: window.sap.m.IconTabDensityMode.Compact,
      backgroundDesign: window.sap.m.BackgroundDesign.Transparent,
      items: ceKeys.map((ceKey, index) => {
        const ceObject = codeeditor[ceKey];
        const ceId = [pageKey, ceKey].join("_");
        const oCe = ceInstances[index];

        return component.set(window.sap.m.IconTabFilter, {
          name: `${ceId}_tab_filter`,
          key: ceId,
          text: ceObject.title,
          customData: customData.set({
            name: `${ceId}_select_handler`,
            key: "select_handler",
            value: { selectHandler: ceObject.onSelect, tabKey: ceId }
          }),
          content: oCe,
          callback: comp => {
            if (ceObject.selected === true) {
              const oTabBar = comp.getParent();
              oTabBar.setSelectedKey(ceId);
            }
          }
        });
      }),
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("noBottomLine");
          comp.addStyleClass("ft-height-100-for-codeeditor");
          comp.setLayoutData(
            component.set(window.sap.m.FlexItemData, {
              name: `${pageKey}_ce_layout`,
              growFactor: 1
            })
          );
        }
      }
    });
    return [ceWrapper];
  } else {
    return ceInstances[0];
  }
}

export {
  fieldComponent,
  buildCodeEditor,
  refineEventForDialog,
  convertNumber,
  convertNumberToNew
};
