import { compose } from "@reduxjs/toolkit";
import { render5 } from "@bsgp/lib-hoc";

import * as ui5 from "./index.ui5";
import { formTable, ft } from "./common";
import * as functions from "./lib/functions";

function Comp() {
  return true;
}
Comp.displayName = "FormTable";

const FormTable = compose(render5(ui5))(Comp);

export { FormTable, formTable, ft, functions };
